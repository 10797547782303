.mainpreview{
    padding-top: 120px;
    padding-bottom: 40px;
    background: #F5F6F7;
}
.postpreviewcontainer{
    background-color:  var(--bg-white-color);
    box-shadow: 0 8px 24px #00000012;
    border-radius: 8px;
    max-width: 60%;
    margin: auto;
    /* padding: 40px 80px; */

}
.backbtn button{
    /* width: 108px;
    padding: 6px 25px;
    background: var(--primary-color);
    color: white;
    border: 1px solid var(--primary-color);
    border-radius: 4px; */
    border: none;
    background: none;
    color: var(--primary-color);
    margin-bottom: 10px;
}
.topjobrow{
    display:flex ;
    justify-content: space-between;
    margin-bottom: 10px;
}
.postpreviewcontainer h2{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    /* padding-top: 10px; */
    line-height: 25px;
    color:  var(--bg-white-color);
}
.postpreviewcontainer h3 , .job-desc h3{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    padding-top: 10px;

    padding-bottom: 15px;
}
.postpreviewcontainer h3{
    color: #4C5966;
}
.job-desc h3{
    color: var(--font-black-color);
}
label {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #828E99;
}
.jobpreview p , .CRlastupdated p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #17181A;
    margin-bottom: 0px !important;
}
.generalpreview{
    border:1px solid #bfbfbf;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 20px;
}
.job-descdetail{
    padding-top: 12px;
}
.datepreview,.jobdescpreview{
    margin-bottom: 10px;
}

.jobpreview-screen{
    padding-top: 13px;
}
.jobpreview-title{
    padding-top: 12px;
}
.highlight {
    background-color: var(--highlight-color);
    font-weight: bold;
}

.jobpreview {
    display: flex;
    align-items: baseline;
    padding-top: 7px;
}
.job-p1 , .job-p2 , .job-p3, .job-p4{
    display: flex;
    align-items: baseline;
}
.job-p2 ,.job-p3, .job-p4{
    margin-left: 20px;
}
.job-desc{
    padding: 20px 0px;
}
.job-desc textarea {
    width: 100%;
    height: 250px;
    background: #F5F6FA;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    border: none;
    line-height: 28px;
    padding: 15px 15px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
}
.labelcol{
    font-weight: 900;
    font-size: 17px;
}
.jobid p{
    padding-left: 15px;
}
.job-btn{
    display: flex;
    justify-content: end;
    padding: 20px 0px;
    
}
.JPhead{
    background: var(--primary-color);
    padding: 20px 9px;
    margin-top: 15px;
    border-radius: 6px;
}
.JPhead label, .JPhead p,.JPhead h3{
    color: white;
}
.already-applied-txt{
    background: var(--third-color);
    border: 1px solid var(--third-color);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    width: 150px;
    text-align: center;
    padding: 6px 0px;
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
}
.apply-now-btn button {
    color:  var(--bg-white-color);
    background-color: var(--primary-color);
    /* margin-top: -20px; */
    width: 108px;
    transition: background-color 0.3s ease;
}

.apply-now-btn button:hover,
.apply-now-btn button:active {
    color:  var(--bg-white-color);
    background-color: var(--primary-color);
}


.job-btn button{
    padding: 10px 30px;
    background:  var(--bg-white-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-color);
}
.job-post :nth-child(1){
    border: 1px solid var(--third-color);
    color: var(--third-color);
    margin-right: 14px;
}
.job-post :nth-child(2){
    border: 1px solid var(--primary-color);
    color:  var(--bg-white-color);
    background: var(--primary-color);
}

@media (max-width:1024px){
    .postpreviewcontainer{
       max-width: 85%; 
    }
}
@media (max-width:600px){
    .postpreviewcontainer{
       max-width: 100%; 
    }
    .CRallsection {
    padding: 20px 20px;
    }
}
@media (max-width:520px){
   .jobcont{
    display: flex;
    flex-direction: column;
    gap: 15px;
   }
.CRmainpreview{
    padding-left:20px !important;
    padding-right: 20px !important;
}
.CRallsection {
    padding: 10px 10px !important;
}
}

@media (min-width:1500px) and (max-width:3000px) {
    .postpreviewcontainer{
        width: 50%;
    }
}
