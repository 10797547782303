@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&family=Yantramanav:wght@100;300;400;500;700&display=swap');
@import "variables.css";
*{
    padding: 0px;
    margin: 0px;

}
#job-action
{
    background:  var(--primary-color);
}
.AJbackbtn button{
    /* width: 108px;
    padding: 6px 25px;
    background: var(--primary-color);
    color: white;
    border: 1px solid var(--primary-color);
    border-radius: 4px; */
    border: none;
    background: none;
    color: var(--primary-color);
    margin-bottom: 10px;
}
.row>*{
    padding: 0px !important;
}
.container-fluid{
    padding: 0px !important;
    /* overflow: hidden; */
}
a{
    text-decoration: none !important;
    background-color: none ;
}
.mainpage1aljob{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    /* background: #F5F6F7; */
}

.resumecol1aljob{
    width: 25%;
    background: var(--primary-color);
    padding: 30px 30px;
    position: fixed;
    overflow-y: scroll;
    top: 75px;
    bottom: 0;
}
.postcol1aljob{
margin-left: 25%;
width: 75%;
padding-top:120px ;
/* padding-bottom:30px ; */
padding-left: 60px;
padding-right: 60px;

}
.alljobfilter div input[type="checkbox"] {
    margin-right: 10px;
}

/*------------------------------------- 1st  Column --------------- */
.resumeupdatealjob select{
    width: 100%;
    background: var(--bg-white-color);
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 5px;
    padding: 6px 14px;
}
/* .round-image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
} */
.AJtitle{
    /* background: var(--primary-color);
    border-radius: 4px;
    margin-bottom: 17px;
    padding: 8px 0px; */
    background: var(--bg-white-color);
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    margin-bottom: 17px;
    padding: 8px 0px;
}
.AJtitle h2{
color: var(--primary-color) !important;
text-align: center !important;
margin: 0px !important;
/*text-transform: uppercase;*/
}
.postanalyticsPSaljob{
    text-align: center;
    background: var(--primary-color);
    color: var(--bg-white-color);
    /* padding: 10px 0px; */
    border-radius: 6px;
    border: 1px solid var(--primary-color);
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:10px;
    padding: 10px 15px;
}
.postanalyticsPSaljob p{
    margin-bottom: 0px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--bg-white-color);
}

.work-authorizaljob{
    margin-top: 30px;
}
.alljobfilter{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: baseline;
    margin-top: 2px;
    padding: 0px 10px;
}
.alljoblabel{
    padding-bottom: 10px;
}
.alljobbox{
    border: 1px solid #c2c2c2;
    background: var(--bg-white-color);
    border-radius: 8px;
    padding-left: 10px;
}
.jobappli{
    text-align: center;
}
.alljoblabel label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #4C5966;
}
.alljobfilter p{
    color: var(--primary-color);
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}
.resumesearch1aljob{
    width: 45%;
}

button .btn{
    border-radius: none !important;
}

hr.rescardinfolinealjob {
    margin: 0px;
    border-top: 1px solid var(--primary-color);
}


.main1aljob{
    background-color: var(--bg-white-color);
} 
.search_datealjob {
    width: 100%;
    background: var(--bg-white-color);
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    padding: 10px 16px;
}
.alljoblist table{
    width: 100%;
}
.alljoblist th:nth-child(1){
border-radius: 6px 0px 0px 0px;
}
.alljoblist th:nth-child(7){
border-radius: 0px 6px 0px 0px;
text-align: center;
}
.alljoblist thead tr{
    border: none;
    border-radius:8px 8px !important;
}
.alljoblist tr:nth-child(8){
border-radius:8px ;
}
.table>thead {
    vertical-align: middle !important;
}
.alljoblstrow tr{
    border-radius:8px ;
}
.alljoblstrow td:nth-child(1){
  border-radius:8px ;  
}
.alljoblist th{
    background: var(--primary-color);
    color: var(--bg-white-color);
    white-space: nowrap;
}
.alljoblist tr{
border: 1px solid var(--bg-white-color);
vertical-align: middle;
 
}
.editjobsicon {
    display: flex;
    flex-direction: row;
    justify-content: end;
    column-gap: 5px;
}
.editjobsicon img{
    width: 25px;
}


.editjobsicon i{
    color:var(--primary-color) ;
    font-size: 18px;
}
.alljoblist td{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 10px;
}
.alljoblist th{
    /*text-transform: uppercase;*/
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    padding: 8px;

}
.alljoblist tr:nth-child(odd) {
background-color: var(--bg-white-color);
}
/*------------------------------------- 3rd  Column --------------- */

/*---------------------------Favourite Resume ------------- */
.favouritealjob{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}
.resumealjob{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.resumealjob p {
    font-family: var(--font-family);
    font-style: normal;
    color:#828E99;
    font-size: 16px;
    font-weight: 400;
}
.resumealjob p a{
    color:#828E99;
}
.resumealjob h2,.resumeupdatealjob h2 , .postcol1aljob h2{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #000000;
}
.Ajlefthead h2{
 color: var(--sidebar-heading-color) !important;
}
.resumealjob h2,.resumeupdatealjob h2 ,.postcol1aljob h2{
    margin-bottom: 20px;
    margin-top: 12px;
}
.fvtresumaljob{
    display: flex;
    align-items: center;
}
.rsfvtresumtxtaljob {
    margin-left: 15px;
}
.rsfvtresumtxtaljob h6{
    font-family: var(--font-family);
    color: #4C5966;
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 20px;
}
.rsfvtresumtxtaljob h6 a{
    color: #4C5966;
}
.rsfvtresumtxtaljob p{
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 20px;
    color: #828E99;
}
.rsfvtresumtxtaljob p a{
    color: #828E99;
}





.dropbtn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.dropdown-contentaljob {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: var(--secondary-color);
    right: 0;
}

.dropdown-contentaljob button {

    background-color: var(--secondary-color);
    color: #333;
    padding: 8px 12px;
    border: none;
    outline: none;
    font-size: 14px;
    cursor: pointer;
}

.dropdown .dropdown-contentaljob {
    display: block;
    background-color: var(--secondary-color);
}
.custom_datealjob{
    display: flex;
    flex-direction: row;
    gap: 7px;
    margin-top: 10px;
}

.ALLjob_sidebar{
    background: white;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
}


@media (max-width:1024px){
.postanalyticsPSaljob{
    justify-content: space-evenly;
}
.resumecol1aljob{
display: none;
}
.postcol1aljob{
   margin-left: 0px;
    width: 100%; 
    padding-left: 40px;
    padding-right: 40px;        
}
}
@media (max-width:575px){
    .postcol1aljob{
        padding-left: 20px;
        padding-right: 20px;
    }
.alljoblist td{
    font-size: 14px;
    line-height: 15px;
}
.alljoblist th{
    font-size: 15px;
    line-height: 16px;
}
.postanalyticsPSaljob p{
  font-size: 15px;
    line-height: 16px;  
}
}

@media (min-width:1065px) and (max-width:1400px){

    .resumecol1aljob{
    width: 25%;
    padding: 10px;
    }
    .postcol1aljob{
    width: 75%;
    padding-top:120px ;
    padding-left: 20px;
    padding-right: 20px;
     margin-left: 25%;
    }
    /* .dashboardcol1aljob{
    width: 22%;
        padding: 20px 20px;
    } */

}

@media (max-width:1064px){

    .resumecol1aljob{
       display: none;
    }
    .postcol1aljob{
        width: 100%;
        padding-top:120px ;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 0;
    }
    /* .dashboardcol1aljob{
    width: 22%;
        padding: 20px 20px;
    } */

}