.table1{
    width: 100%;
}
.table1 th:first-child{
    border-radius: 6px 0px 0px 0px;
}
.table1 th:last-child{
    border-radius: 0px 6px 0px 0px;
}
.table1 thead tr{
    border: none;
    border-radius:8px 8px;
}


.table1 th{
    background: var(--primary-color);
    color:  var(--bg-white-color);
    white-space: nowrap;
}
.table1 tr{
    border: 1px solid #d8d8d8;
    vertical-align: middle;

}
.table1 td{
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    /*line-height: 20px;*/
    padding: 10px 10px;
    white-space: nowrap;
}
.table1 th{
    /*text-transform: uppercase;*/
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    /*line-height: 21px;*/
    padding: 8px;

}
.table1 tr:nth-child(odd) {
    background-color: #f3f3f3;
}