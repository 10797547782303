@import "variables.css";

.user_info{
    background-color: var(--sidebar-bg);
    width: 30%;
    padding: 10px;
    max-height: 300px;
    border-radius: 8px;

}
.user_info p{
    color: var(--sidebar-text-color);
    margin: 0;
}

.rightContent{
    background-color: #ededed;
    border-radius: 8px;
    width: 70%;
    margin-bottom: 3rem;
}

.rightContent_scroll{
    height: 65vh;
    overflow-y: scroll;
}

.rightContent_scroll::-webkit-scrollbar {
    width: 0;
}

@media (max-width: 1024px) {

    .rightContent{
        width: 100%;
    }
    .user_info{
        display: none;
    }
}