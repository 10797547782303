/* social-media-posts.css */
@keyframes likeAnimation {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

.show-like-animation {
    animation: likeAnimation 0.5s ease-in-out;
}

.sm-no-posts{
    margin-top: 20px;
}
.sm-img-style{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
.sm-small-img-style{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}