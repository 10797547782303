.JBmain{
    padding-top: 120px;
}
.JBmain table{
    width: 100%;
}
.JAbackbtn button{
    /* width: 108px;
    padding: 7.5px 25px;
    background: var(--primary-color);
    color: white;
    border: 1px solid var(--primary-color);
    border-radius: 4px; */
    border: none;
    background: none;
    color: var(--primary-color);
    margin-bottom: 10px;
}
.AJtitle2 {
    /* background: var(--primary-color); */
    background-color:  var(--bg-white-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    margin-bottom: 17px;
    padding: 8px 0;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    
}

.AJtitle2 h2 {
    color: var(--primary-color) !important;
    margin: 0!important;
    text-align: right !important;
    font-family: var(--font-family);
    /*text-transform: uppercase;*/
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    
}
.AJtitle2 p{
  color: var(--primary-color) ;
  text-align: end; 
  margin-bottom: 0; 
  margin-right: 10px;
  cursor: pointer;
}
.JBmain td {
    text-align: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 12px;
}
.JA-viewresume{
    background: var(--third-color) !important;
    border: 1px solid var(--third-color) !important;
    color:  var(--bg-white-color);
    border-radius: 4px;
    width: fit-content;
    padding: 3px 6px;
    cursor: pointer;
}

.JBmain th{
    white-space: nowrap;
}

.JBmain thead tr{
border: none !important;
}
.JBmain th:first-child{
   border-radius: 8px 0px 0px 0px; 
}
.JBmain th:last-child {
border-radius: 0px 8px 0px 0px;
text-align: center;
}
.JBmain thead tr{
    border-radius:8px 8px !important;
    border: none;
}
.JBmain tr:nth-child(8){
border-radius:8px ;
}
.JBmain tr{
    border-radius:8px ;
    vertical-align: middle;
}
.JBmain td:nth-child(1){
  border-radius:8px ;  
}
.JBmain th{
    background: var(--primary-color);
    color:  var(--bg-white-color);
}
.JBmain tr{
border: 1px solid #d8d8d8; 
 
}
.JBmain th{
    /*text-transform: uppercase;*/
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    padding: 12px;
}
.JBmain tr:nth-child(odd) {
background-color:  var(--bg-white-color);
}
.JBmain #job-action
{
    background: var(--primary-color);
}
.AJstatus_btn{
    background:  var(--bg-white-color);
    color: var(--primary-color);
    padding: 3px 6px;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    width: fit-content;
    white-space: nowrap;
}
#job-action {
    /* background-color: your-background-color;  */
    color: var(--font-black-color);
    padding: 4px 3px;
    border-radius: 4px;
}
.table>:not(caption)>*>*{
    border-bottom: none !important;
    padding:none !important;
    background-color: none !important;
    box-shadow: none !important;
}
#job-action option {
    background-color:  var(--bg-white-color);
    color: var(--font-black-color);
}

.comment-expandable {
    max-height: 46px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}
.comment-expanded {
    max-height: 180px; /* Adjust this value based on your needs */
}
.scrollable-container {
    max-height: 300px; /* Adjust this value as needed */
    overflow-y: auto;
}

@media (max-width:575px){
    .postcol1aljob{
        padding-left: 20px;
        padding-right: 20px;
    }
.JBmain td{
    font-size: 14px;
    line-height: 15px;
}
.JBmain th{
    font-size: 15px;
    line-height: 16px;
}

}

