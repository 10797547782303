/* Add this CSS to your component's CSS file or a global CSS file */
.mc-custom-tab {
    background-color: var(--primary-color) !important;
    color:  var(--bg-white-color) !important;
}
/* mycircles.css */

.tabs_custom{
    position: relative !important;
    z-index: 1 !important;
}
/* Define the custom class for the Follow button */
.btn-outline-custom {
    color: var(--primary-color);
    border-color: var(--primary-color);
    /*width: 7rem;*/
}

/* Style the button on hover */
.btn-outline-custom:hover {
    background-color: var(--primary-color);
    color:  var(--bg-white-color);
}
/* Add these custom CSS classes */
.followers-list {
    z-index: 1040 !important; /* Set your desired z-index value */
    width: auto !important; /* Set your desired width value */
}

.followings-list {
    z-index: 1040 !important; /* Set your desired z-index value */
    width: auto !important; /* Set your desired width value */
}

