/* ----------------------------------------------------------------- */
/*                         Post A JOb                               */
/* ----------------------------------------------------------------- */
.main-CP{
    padding-top: 40px;
    background-color: #F5F6F7;
}
.mainHeading-CP{
margin-top: 70px !important;  
max-width: 60%;
margin: auto;
padding: 20px 0px;
/* display: flex; */
justify-content: space-between;
align-items: center;
}
.mainHeading-CP h2{
    font-family: var(--font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.profiledate-CP{
    display: flex;
    gap: 30px;
}
.profiledate-CP p{
    color: #828E99;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0px;
}
.profiledate-CP span{
    color: #010101;
    padding-left: 15px;
}
.postcontainer-CP{
    background-color: var(--bg-white-color);
    /* border-radius: 8px; */
    max-width: 60%;
    margin: auto;
    padding: 40px 70px; 
    border-top: 8px solid var(--secondary-color);
    border-radius: 6px;
}
/* ---------------------------- Personal Information -----------------------*/

.profilemaindata-CP{
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 20px;
}

.Prophoto-CP{
    border: 1px solid #a7a7a7;
    text-align: center;
    width: 40%;
    margin-top: 5px;
    /*height: 140px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}
.Prophoto-CP P{
font-size: 14px;
    color: var(--font-black-color);
    margin: 0px;
    font-weight: 700;

}
.Prodata-CP{
display: flex;
flex-direction: column;
row-gap: 15px;
width: 80%;
}
.Proname-CP{
    display: flex;
    gap: 0.9rem;
}
.savebtn-CP{
    display: flex;
    flex-direction: row;
    column-gap: 6px;
}
.Prodata-CP textarea{
    width: 100%;
    padding: 10px 16px;
    border: 1px solid rgba(46, 46, 46, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}
.Profile-Content-IW{
    width: 100%;
}
.savebtn-CP button{
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: var(--bg-white-color);
    border-radius: 8px;
    padding: 6px 16px;
    font-size: 13px;
}

.expsection-CP,.institute-CP ,.dateselect-CP,.degreemajor-CP,.addbtn-CP,.savebtn-CP button{
    margin-top: 24px;
}
.dateselect-CP label{
    padding-bottom: 10px;
}
.degmajor-CP,.strdat-CP, .enddat-CP{
    width: 48%;
}
.degreemajor-CP,.dateselect-CP,.expdtl3-CP{
    display: flex;
    justify-content: space-between;
}
.expsection-CP{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.expheading-CP{
  display: flex;
    align-items: center;
    column-gap: 10px;
}
.expheading-CP h5{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin: 0px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: var(--primary-color);
}
/* ------------------------Contact section --------------------------------- */

.userLocation{
    width: 50%;

}
.location-CP span,.expdtl2-CP span,.expdtl3-CP span,.expdtl1-CP span{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /*color: var(--font-black-color);*/
    color: #4C5966;

    margin-bottom: 0px;
    text-align: justify; 
    padding-left:6px;
}

.addanother-CP button{
    background: none;
    border: none;
}
.addbtn-CP button{
    padding: 4px 20px;
    background: var(--third-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    border-radius: 50px;
    color: var(--bg-white-color);
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.startdate-CP,.enddate-CP,.degree-CP,.major-CP {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid rgba(46, 46, 46, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}
.startdate, .enddat-CP{
    display: flex;
    flex-direction: column;
}
.expdtl-CP{
    background: var(--bg-white-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    border-radius: 8px;
    padding: 15px 20px;
    margin-top: 24px;
}
.expdtl2-CP p,.expdtl3-CP p,.expdtl1-CP p ,.expdtl4-CP p,.mobilenum-CP p,.location-CP p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    /*color: #4C5966;*/
    color: black;
    margin-bottom: 0px;
    /*text-align: justify;*/
    /*padding: 3px 7px;*/
}
.expdtl1-CP h4{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.hobbieimg img{
    width: 27px;
    height: 27px;
}
.profileimg-CP img{
    width: 100px;
    height: 100px;
}
.expdtl-img-CP{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
}
.expdtl-img-CP img{
    width: 28px;
}
.addanother-CP img{
    width: 25px;
    height: 25px;
}



label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: var(--font-black-color);
}


.crudbtn-CP {
    display: flex;
    justify-content: end;
}
.crudbtn-CP button{
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 4px 15px;
    border-radius: 4px;
}

@media (max-width: 1300px){
    .mainHeading-CP{
        max-width: 95%;
    }
    .postcontainer-CP{
        max-width: 95%;
    }
}
@media (max-width: 1024px){
    .profilemaindata-CP{
        align-items: center;
    }
    .Proname-CP{
       flex-direction: column;

    }

}
@media (max-width: 768px){
    .postcontainer-CP{
       padding: 20px;

    }

}

@media (max-width: 600px){

    .profilemaindata-CP{
        flex-direction: column;
    }

    .Prodata-CP{
        width: 100%;
    }
    .degreemajor-CP, .dateselect-CP, .expdtl3-CP{
        flex-direction: column;
        gap: 1.2rem;
    }
    .degmajor-CP, .strdat-CP, .enddat-CP{
        width: 100%;
    }
    .profiledate-CP{
        flex-direction: column;
        gap: 1.2rem;
    }

}

