@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&family=Yantramanav:wght@100;300;400;500;700&display=swap');
@import "variables.css";

* {
    padding: 0px;
    margin: 0px;

}

.row > * {
    padding: 0px !important;
}

.container-fluid {
    padding: 0px !important;
    /* overflow: hidden; */
}

a {
    text-decoration: none !important;
    background-color: none;
}

/*.input-field {*/
/*    height: 38px;*/
/*    padding: 8px;*/
/*    font-size: 14px;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 1px;*/
/*}*/
.mainpage1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.highlight {
    background-color: var(--highlight-color);
    /*color: var(--font-black-color);*/
    font-weight: bold;


}

.resumecol1 {

    width: 25%;
    background-color: var(--sidebar-bg);
    padding: 30px 30px;
    position: fixed;
    overflow-y: scroll;
    top: 75px;
    bottom: 0;
}

.postcol1 {
    margin-left: 25%;
    width: 50%;
    height: 100vh;
    padding-top: 100px;
    /*position: fixed;*/
    /* padding-bottom:30px ; */
    padding-left: 60px;
    padding-right: 60px;

}

.postcol1-otr {
    margin-left: 25%;
    width: 50%;
    height: 100vh;
    padding-top: 25px;
    /* padding-bottom:30px ; */
    padding-left: 60px;
    padding-right: 60px;

}

.JSleftheading h2 {
    color: var(--sidebar-heading-color) !important;
}

.dashboardcol1 {
    width: 25%;
    background-color: var(--sidebar-bg);
    padding: 30px 30px;
    position: fixed;
    overflow-y: auto;
    top: 75px;
    bottom: 0;
    right: 0;
}

.dashboardcol1::-webkit-scrollbar {
    width: 0;
}

.text-white {
    color: var(--sidebar-heading-color) !important;

}

.resumesercard-main {
    margin-bottom: 30px;
    margin-top: 10px;
    overflow-y: auto; /* Change to 'scroll' if you always want to show the scrollbar */
    display: flex; /* or display: grid; */
    flex-direction: column; /* or grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
}

.no-results-container {
    margin-top: 20px !important;
}

.resumesercard-main :nth-child(1) {
    margin-top: 0px;
}


.blur {
    -webkit-filter: blur(5px);
}

.divider {
    flex-wrap: wrap;
    display: flex;
}

.add-line {
    border-bottom: 1px solid grey;
    width: 100%;
}

.full-width-p {
    width: max-content;
}

/*.f-width-div{*/
/*    padding-left: 10px;*/
/*}*/
.f-width-div {
    display: flex;
    flex-wrap: wrap; /* Wrap content to the next line if it exceeds the container width */
    align-items: center; /* Align items vertically */
    margin-right: 5px; /* Add some space between the image and the skills */

}

.f-width-div img {
    margin-right: 5px; /* Add some space between the image and the skills */
}

.f-width-div .info-span {
    /*white-space: nowrap; !* Prevent skills from breaking into multiple lines *!*/
    margin-left: 5px; /* Add some space between the skills */
}

.work-experience div input[type="checkbox"] {
    margin-right: 10px;
}

.keyword-match {
    padding: 4px 8px; /* Adjust the padding values as needed */
    margin-right: 4px; /* Add space between each keyword match */
    border-radius: 4px;
    color: white;
    font-weight: bold;
    /* Add any other desired styles */
}


/*------------------------------------- 1st  Column --------------- */
.resumeupdate select {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 5px;
    padding: 6px 14px;
}

.round-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.work-authoriz {
    margin-top: 30px;
}

.work-authorizbord {
    border: 1px solid #c2c2c2;
    background: white;
    border-radius: 8px;
    padding: 10px 10px;
}

.work-experience {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 2px;
}

.work-experience p {
    color: var(--primary-color);
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}

.resumesearch1 {
    width: 45%;
}

/*------------------------------------ Second Column ------------------------------ */
.searresume1 {
    background: var(--bg-white-color);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    padding: 10px 10px;
    border: 1px solid var(--primary-color);
}

.searchcard2 {
    margin-top: 15px;
    margin-right: 5px;
    /*overflow-x: scroll;*/
    scrollbar-width: thin; /* "auto" for default browser style, "thin" for a thinner scrollbar */
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1); /* thumb color and track color */
}


/* .resumesearch-bar{

background: #FFFFFF;
box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05) !important;
border-radius: 8px;
border: 1px solid rgba(0, 0, 0, 0.15);
} */
.resumesearch-bar select {
    border: none !important;
    z-index: 333;

}

.searchtitle, .searchcityco {
    width: 100%;
}

.resumesearch-bar input[type="text"] {
    border: 1px solid hsl(0, 0%, 80%);
    padding: 6px 10px;
    border-radius: 4px;
}

/*cross button*/
.input-container {
    position: relative;
}

.clear-button {
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #999999;
    padding: 0;
    font-size: 2.5rem;
}

.clear-button:hover {
    color: var(--font-black-color);
}


.resumesearch-bar ::placeholder {
    font-weight: 400;

}

.resumesearch-bar .btn {
    border: 1px solid var(--primary-color) !important;
    padding: 7px 20px;
    margin-top: 44px;
    background: var(--primary-color);
    color: var(--bg-white-color);
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.resumesearch-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

button .btn {
    border-radius: none !important;
}

.resumesearch-bar .red-btn {
    background: var(--secondary-color) !important;
    border: unset !important;
    color: var(--bg-white-color) !important;
}

.info1-img {
    display: flex;
    column-gap: 10px;
}

/*.info1-img :nth-child(3){*/
/*    padding-left: 10px;*/
/*}*/
/*.info1-img :nth-child(1){*/
/*    padding-right: 10px;*/
/*}*/
.total_resume {
    display: flex;
    flex-direction: row;
    padding-top: 37px;

}

.total_resume h2:nth-child(1) {

    font-size: 20px;
    font-family: var(--font-family);
    font-style: normal;
    /*font-weight: 450;*/
    line-height: 0px;
    color: black
}

.total_resume h2:nth-child(2) {
    color: var(--secondary-color);
    /*font-style:italic !important;*/
    font-size: 20px;
    font-family: var(--font-family);
    font-style: normal;
    /*font-weight: 450;*/
    line-height: 0px;
}

.searresume1, .info1, .info2, .info3, .info4 {
    display: flex;
}


.info3, .info4 {
    justify-content: space-between;
}

.info3, .info4, .info5 {
    padding-top: 8px;
}

.searresume1 {
    align-items: flex-start;
}

.info5 {
    display: flex;
    align-items: baseline;
}

.searresume1 p {
    margin: 0px;
}

.info1 {
    justify-content: space-between;
    align-items: center;
}

.info1 p, .info2 p, .info3 p, .info4 p, .info5 p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    padding: 4px 0px;
}

.info1 p, .info2 p {
    color: var(--primary-color);
}

.info3 p, .info4 p, .info5 p {
    color: var(--font-black-color);
}

.info-span {
    color: #17181A;
    /*margin-left: 4px;*/
    /*margin-right: 10px;*/
    font-size: 14px;
    font-weight: 400;
}

hr.rescardinfoline {
    margin: 0px;
    border-top: 1px solid var(--primary-color);
}

.skillspan {
    font-size: 13px;
    font-weight: 400;
    color: #17181A;


    /* color: #265699 !important; */
}

.info1 p {
    font-size: 18px;
    font-weight: 600 !important;
}

.info2 p, .info3 p, .info4 p, .info5 p {
    font-size: 15px;
}

.maininfo {
    margin-left: 10px;
    width: 100%;
}

/* .main1{
    background-color: #F5F5F5;
} */
.search_date {
    width: 100%;
    background: var(--bg-white-color);
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    padding: 10px 16px;
}

/*------------------------------------- 3rd  Column --------------- */

/*---------------------------Favourite Resume ------------- */
.favourite {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.resume {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.resume p {
    font-family: var(--font-family);
    font-style: normal;
    color: var(--sidebar-text-color);
    font-size: 16px;
    font-weight: 400;
}

.resume p a {
    color: #1c1c1c;
}

.resume h2, .resumeupdate h2, .postcol1 h2, .postcol1-otr h2 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #17181A;
}

.resume h2, .resumeupdate h2 {
    margin-bottom: 20px;
    margin-top: 12px;
}

.postcol1 h2, .postcol1-otr h2 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.postanalyticsRS {
    text-align: center;
    background: var(--primary-color);
    color: var(--bg-white-color);
    padding: 10px 0px;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}

.postanalyticsRS p {
    margin-bottom: 0px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: var(--bg-white-color);
}

.fvtresum {
    display: flex;
    align-items: center;
}

.rsfvtresumtxt {
    margin-left: 15px;
}

.rsfvtresumtxt h6 {
    font-family: var(--font-family);
    /* color: #4C5966; */
    color: var(--sidebar-heading-color);
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 20px;
}

.rsfvtresumtxt h6 a {
    /* color: #4C5966; */
    color: #1c1c1c;
}

.rsfvtresumtxt p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 20px;
    /* color: #828E99; */
    color: #fff;
}

.rsfvtresumtxt p a {
    color: var(--sidebar-text-color);
}

.basic_text {
    color: var(--sidebar-text-color);
}


.dropbtn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    backgroundColor: "#f44336";
    right: 0;
}

.dropdown-content button {

    backgroundColor: "#f44336";
    color: #333;
    padding: 8px 12px;
    border: none;
    outline: none;
    font-size: 14px;
    cursor: pointer;
}

.dropdown .dropdown-content {
    display: block;
    backgroundColor: var(--secondary-color);
}

.custom_date {
    display: flex;
    flex-direction: row;
    gap: 7px;
    margin-top: 10px;
}

.title-search {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 222;
}

.city-search {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-top: 10px
}

.searchbtnRS {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.city-search label, .title-search label {
    padding-bottom: 10px;
}

.location_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.sidebarFilter {
    border-radius: 4px;
    border: 1px solid var(--primary-color);
}


@media (min-width: 1024px) and (max-width: 1400px) {

    .resumecol1 {
        width: 25%;
        padding: 8px;
    }

    .postcol1, .postcol1-otr {

        width: 50%;
        padding-top: 100px;
        /* padding-bottom:30px ; */
        padding-left: 20px;
        padding-right: 20px;
        margin: auto;
    }

    .dashboardcol1 {

        width: 25%;
        padding: 8px;
    }

    .city-search {
        width: 66%;
    }

    .resumesercard-main {
        height: 300px;

    }

}

/* @media (min-width:1200px) and (max-width:1640px){
.info5{
flex-wrap: wrap;
column-gap: 40px;
}      
}
@media (min-width:1641px) and (max-width:3000px){
  .info5{
 justify-content: space-between;
}   
} */
@media (max-width: 1024px) {
    .resumecol1, .dashboardcol1 {
        display: none;
    }

    .postcol1, .postcol1-otr {
        width: 100%;
        /*margin-left: 0px;*/
        margin: 0;
    }

    .city-search, .searchbtnRS {
        width: 100%;
    }
}

@media (max-width: 520px) {

    .postcol1, .postcol1-otr {
        padding-left: 15px;
        padding-right: 15px;
    }

    .maininfo {
        margin-left: 0px;
    }

    .searresume1 {
        flex-direction: column;
    }

    .postanalyticsRS {
        flex-direction: column;
        row-gap: 1rem;
        padding: 10px 0px
    }

    .location_container {
        flex-direction: column;

    }

    .resumesearch-bar .btn {
        margin-top: 0;
    }
}