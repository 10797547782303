.content_container{
    padding-top: 150px;
}
.content_container h2, .content_container p{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
.content_container p{
  font-size: 18px;
}

.content{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;

}
.content p{
    background-color: var(--primary-color);
    padding: 10px 30px;
    width: 45%;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}
.background_red {
    background-color: var(--secondary-color) !important;

}
.content p:focus {
    color: lightblue; /* Change the color to your desired value */
}