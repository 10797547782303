.mainbg {
    background-color: #F5F5F5;
    width: 100%;
    height: 100vh;
    padding-top: 90px;
    display: flex;
    background-image: url('/src/assets/background_blue.png'); /* Replace 'path/to/your/image.jpg' with the path to your image */
    justify-content: center;
}
.mainbg1 {
    background-color: #F5F5F5;
    width: 100%;
    height: 100%;
    padding-top: 90px;
    display: flex;
    background-image: url('/src/assets/background_blue.png'); /* Replace 'path/to/your/image.jpg' with the path to your image */
    justify-content: center;

}
.success-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;

}

.success-message {
    display: flex;
    align-items: center;
    background-color: var(--third-color);
    color: white;
    padding: 10px;
    border-radius: 8px;
    font-size: 20px;
    text-align: center;
}
.card-group {
    flex-flow: column !important;
}
.card-flex{
    display: flex;
}
.package-label {
    font-size: 1.55rem; /* Adjust the font size as needed */
    margin-left: 10px;

}
.about-rezmaze-package-label {
    font-size: 1.55rem; /* Adjust the font size as needed */

}

.success-message .icon {
    margin-right: 8px; /* Adjust as needed */
}

.success-message h4 {
    margin: 0; /* Remove default margin for h4 */
}


.form {
    background-color:  var(--bg-white-color);
    margin: auto;
    max-width: 500px;
    width: 100%;
    padding: 50px 60px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    border-radius: 16px;

}

.form1 {
    background-color:  var(--bg-white-color);
    max-width: 600px;
    width: 100%;
    padding: 50px 60px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    border-radius: 16px;

}

.emailinfo {
    display: flex;
    padding-left: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}

.passwordinfo {
    margin-top: 20px;
    padding-right: 10px;
}
.eye-icon-container {
    display: flex;
    align-items: center;
    margin-top: 3px; /* Adjust the margin-top as needed */
    margin-left: 1px; /* Adjust the margin-top as needed */

}

.eye-icon {
    cursor: pointer;
}

.emailinfo input[type="text"], .emailinfo input[type="password"] {
    border: none;
    width: 100%;
    padding: 12px 10px;
    border-radius: 8px;
}

.formdata h2 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #4C5966;

}

.formdata p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #5079B3;
    margin-left: 0px
}
.checkbox-label{
    margin-left: 10px;
}

.formforget {
    margin-top: 20px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
}

.formforget p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-color);
}

.formforget label {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.formbtn {
    margin-top: 20px;
}
.postbtn button{
    border-radius: 50px;
    width:  150px;
    padding: 8px 30px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color:  var(--bg-white-color);
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
}
.formbtn button {
    width: 100%;
    padding: 12px 0px;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    color: #FFFFFF;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.another-btn {
    width: 100%;
    padding: 10px 0px;
    background: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    color:  var(--bg-white-color);
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.full-width{
    width: 100%;
}
.formbtn button a {
    text-decoration: none;
    color:  var(--bg-white-color);
}

.formbtn p {
    margin-top: 24px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C5966;
}

.formbtn span a, .formforget p a {
    color: var(--primary-color);
    text-decoration: none;
}

.subscription_content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    gap: 1rem;
    position: relative;

}
.sp-top-height{
    margin-bottom: 10px;
    top: 120px;
    width: 30%;
    gap: 1rem;
}
.subscription_info{
    width: 30%;
    /*top: 120px;*/
    position: sticky;
}
.sp-custom-card {
    border-radius: 16px !important;

}
.sp-custom-card .card-text p:nth-child(odd) {
    margin-bottom: 5px; /* Adjust the margin as needed */
    margin-top: 5px;
}


@media (max-width: 1100px){
    .subscription_content{
        width: 100%;
    }
}

@media (max-width: 950px){
    .subscription_content{
        flex-direction: column;
        gap: 1rem;
    }
    .sp-top-height{
        top: 0;
        position: relative !important;
    }

}


@media (max-width: 768px){

    .form{
        padding: 15px;
        margin: 0;
    }


}