/* VideoResumeGuide.css */

.smc-background-style {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
}

.smc-social-media-content {
    display: flex;
    font-family: var(--font-family);
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
}


.smc-text-content {
    width: 60%;
    float: left;
}

.smc-image-content {
    width: 40%;
    float: left;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px; /* Add padding to the container */
    background-color: #fff; /* Add a background color */
    border: 2px solid var(--font-black-color); /* Add a border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a box shadow */
    border-radius: 8px; /* Add border radius for rounded corners */

}

.smc-image-content img {
    max-width: 100%;
    max-height: 100%;
}
/* Style headings */
.smc-social-media-content h1 {
    font-size: 24px;
    font-weight: bold;
    /* Add any other heading styles here */
}

/* Style paragraphs */
.smc-social-media-content p {
    font-size: 16px;
    /* Add any other paragraph styles here */
}



.smc-ol-clickable:hover {
    color: var(--secondary-color); /* Change the color when hovering */
}
.smc-ol-clickable{
    /*text-decoration: underline;*/
    cursor: pointer;
    color: var(--primary-color); /* Change the color to a link color */
}
