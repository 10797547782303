@import "variables.css";

.Profile_Container {
  width: 100%;
  padding-top: 120px;
  background-color: #f5f6f7;
}
.main_pv_container {
  width: 80%;
  margin: 0 auto;
}

.pv_top_header {
  position: relative;
}

.profile_backgound {
  width: 100%;
}
.disabled_button {
  /*background-color: #ccc !important;*/
  /*color: #999 !important;*/
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled_button:hover {
  opacity: 0.6;
}


.profile_top_content {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: end;
  width: 100%;
  height: 380px;
  object-fit: cover;

}

.profile_top_content img {
  width: 100%;
  height: auto;
  object-fit: cover;
}


.profile_top_left_content {
    position: absolute;
    bottom: 5px;
    left: 10px;
}
.profile_top_left_content img{
  width: 140px;
  height: 140px;
  border-radius: 10px 10px 10px 10px;
  object-fit: cover;
}

.profile_top_right_content {
    display: flex;
    flex-direction: row;
    /* column-gap: 1.3rem; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background:  var(--bg-white-color);
    padding: 15px 15px;
    margin-top: -3px;
    /*margin-right: 2px;*/
    /*margin-left: 6px;*/
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}
.profile_name_heading h4{
  padding-left: 160px;
  margin: 0;
}
.profile_icons{
  display: flex;
  column-gap: 14px;
}


.profile_top_right_content button {
  padding: 5px 10px;
  color: #fff;
  background-color: var(--primary-color);
  /*text-transform: uppercase;*/
  font-size: 13px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

.pv_below_content {
  margin-top: 10px !important;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 1.2rem;
}
.pv_left_below {
  width: 40%;
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
  /*position: sticky;*/
  /*top: 100px;*/
}

.pv_left_detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.6rem;
}
.pv_left_detail>h3{
  
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 20px;
  font-family: var(--font-family);

}
.pv_left_detail_desc {
  /* height: 300px; */
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 0.6rem;
}
.pv_left_detail_desc p{
  color: #4b4b4b;
  font-weight: 400;
  font-size: 16px;
  font-family: var(--font-family);
  margin-bottom: 0px;

}
.pv_left_detail_content1_section {
  display: flex;
  align-items: baseline;
  column-gap: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
}
.pv_left_detail_content1_section>svg{
  width: 6%;
}
.pv_left_detail_content1_section p{
  color: var(--font-black-color);
  font-weight: 600;
  font-size: 17px;
  font-family: var(--font-family);
  width: 94%;
}
.pv_left_detail_content1_section span{
  color: #4b4b4b;
  font-weight: 400;
  font-size: 16px;
  font-family: var(--font-family);
}
.pv_blue_content {
  color: var(--primary-color);
}

.follower_main_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 0.6rem;
}

.follower_content {
  height: 150px;

  border-radius: 10px;
  width: 33%;
}

.follower_content img {
  width: 100%;
  border-radius: 10px;
}
.follower_content h5 {
  color: var(--primary-color);
  padding: 10px 0px;
}

/* .pv_below_content p {
  margin: 0;
  font-weight: 600;
} */

.pv_right_below {
  width: 60%;
}
.follower_heading{
  color: var(--sidebar-heading-color);
}
@media (max-width: 1024px){

  .pv_below_content{
    flex-direction: column;
  }
  .pv_right_below{
    width: 100%;
  }
  .pv_left_below{
    width: 100%;
  }
.profile_top_left_content {
    position: absolute;
    bottom: 0px;
    left: 0px;

}
}

@media (max-width: 768px){
  .main_pv_container{
    width: 95%;
  }
  .profile_top_left_content img{
  width: 90px;
  height: 90px;
}
.profile_name_heading h4  {
    padding-left: 90px;
   font-size: 1rem;
}
  .profile_top_content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: end;
    width: 100%;
    height: 150px;
    background-size: cover;
  }
}