/* ----------------------------------------------------------------- */
/*                         Post A JOb                               */
/* ----------------------------------------------------------------- */
.mainpostjob {
    padding-top: 120px;
    background: #F5F6F7;
}
.postjobcontainer{
max-width: 60%;
background-color: white;
box-shadow: 0 8px 24px #00000012;
    border-radius: 8px;
     margin: auto;
}
.postcontainer2 {
    background-color: #fff;
    padding: 40px 80px;

}
.EDJhead h2{
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 500;
    font-family: var(--font-family);
    margin-bottom: 0px !important;
}
.textdesc li:hover
{
    color:var(--font-black-color) !important;
    background-color: #fff !important;
}
.CRjobpreview li:hover
{
    color:var(--font-black-color) !important;
    background-color: #fff !important;
}

.postcontainer2 h2 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #17181A;
}

.fields {
    margin-top: 20px;
}

.formheading {
    display: flex;
    align-items: self-end;
    margin-top: 20px;
}
.textdesc {
    margin-top: 20px;

}
.toggle-radio > input + label {
    background-color: #e5e5e5 !important;

}
.textdesc textarea {
    width: 100%;
    height: 250px;
    padding: 10px;
    background: #F5F6FA;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    border: none;
}

.formheading h5 {
    margin-bottom: 0px;
    padding-left: 10px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #17181A;
}

.jobfield {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

}

.jobfield input[type=text] {
    background: #e1e1e1;
}

input[type=text] {
    width: 100%;
    padding: 8px 16px;
    /*opacity: 0.75;*/
    border: 1px solid rgba(46, 46, 46, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;

}

.job-id, .job-date {
    width: 48%;
}

label {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4C5966;
}

.job-title, .job-location, .job-type, .job-textterm, .job-worktype, .bonus-type, .job-year {
    margin-top: 24px;
}
.hr-style2{
    height: 1px;
    margin-left: 5%;
    width: 95%;
    margin-top: 35px;
    margin-bottom: 35px;
}
.location-change{
    display: flex;
    justify-content: space-between;
}
.pj-checkbox-text {
    margin-left: 8px; /* Adjust the margin value as needed */
}


/* .job-nature{
  background: #F5F6FA;
  display: flex;
  padding: 10px 10px;
  justify-content: space-around;
} */
.job-type label, .work-type label, .relocation label, .compension-type label, .bonus-type label, .job-year label,
.job-id label, .job-date label,.certi-type label,.job-title label,.interview-rounds label,.text-term label,.company label {
    padding-bottom: 10px;
}

.job-nature button {
    width: 30%;
    background: #F5F6FA;
    border: none;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #17181A;
}

.job-textterm {
    display: flex;
    justify-content: space-between;
}

.job-textterm select, .job-year select {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid rgba(69, 69, 69, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}

.text-term, .company,.interview-rounds {
    width: 48%;
}
.zipcode{
    width: 100%;
}

.work-type, .relocation {
    width: 48%;

}

.job-worktype {
    display: flex;
    justify-content: space-between;
}

.compension-type, .hourly,.certi-type, .bouns-form, .bonus-percent {
    width: 48%;
}

.bonus-type {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.hourly input[type=text] {
    margin-top: 32px;
}
.certi-type-input{
    padding: 9px 16px !important;
}
.interview-input{
     padding: 8px 16px !important;
}
.jobloc-input{
    
}

.crudbtn {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
}
.hr {
    margin-top: 20px !important;
}
.crudbtn button {
    padding: 8px 25px;
    margin-top: 10px;
    border-radius: 8px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
}
.PJbtn button{
    border: 1px solid var(--primary-color) !important;
    background: var(--primary-color) !important;
    color:  var(--bg-white-color) !important;
    padding: 8px 25px;
    margin-top: 10px;
    border-radius: 8px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
}
.PJbtn1 button {
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color)!important;
    background:  var(--bg-white-color);
    padding: 8px 25px;
    margin-top: 10px;
    border-radius: 8px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
}

/* .crudbtn :nth-child(4) {
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    color: white;
} */

/* ------------------------Description paragraph ---------------------- */
.textdesc {
    margin-top: 20px;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    height: 250px !important;
    border: 1px solid rgba(46, 46, 46, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;

}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused), .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-right-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}


/*------------------------------------------- Segment control ------------------ */

.toggle-radio {
    display: flex !important;
    justify-content: space-between;
    border: 1px solid rgba(76, 89, 102, 0.15);
    border-radius: 6px;
    background: #F5F6FA;

}

.toggle-radio label {
    border: none !important;
    width: 50%;
    text-align: center;
    padding-top: 12px !important;
    padding-bottom: 10px !important;
    border-radius: 8px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #4C5966;

}
.labelcol{
    font-weight: 900;
    font-size: 17px;
}
hr.postjobline {
 
 border-top: 5px solid var(--primary-color) !important;
  border-radius: 5px;
  border-color: var(--primary-color) !important;
 margin-top:30px;
 margin-bottom: 30px;
}
.postanalyticsPS{
    text-align: center;
    background: var(--primary-color);
    color:  var(--bg-white-color);
    padding: 10px 0px;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}
.postanalyticsPS p{
    margin-bottom: 0px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color:  var(--bg-white-color);
}
.posjobhead{
    /*padding-left: 19px;*/
}

.toggle-radio > input:checked + label {
    /*background: white !important;*/
    background: var(--primary-color) !important;
    color:  var(--bg-white-color) !important;
}

/* --------------------------------- Year of experience ----------------------------- */
.required-field {
    /*margin-left: 1px;*/
    font-size: 25px;
    color: var(--secondary-color) ;
}


.job-year {
    width: 48%;
}
/*-------------------------Mobile Screen ----------------------- */
@media (max-width:1143px) and (min-width:700px) {
.postjobcontainer{
        max-width: 95%;
        padding: 0;
}
.toggle-radio > input + label:before{
    height: 0px;
}
}
@media (max-width:699px) {
.postjobcontainer{
        max-width: 95%;
        /*padding: 40px 30px;*/
}
.postanalyticsPS{
    flex-direction: column;
    row-gap: 20px;
}
.jobfield, .job-textterm,.job-worktype{
    flex-direction: column;
}
.job-id, .job-date, .text-term,.job-year,.company, .interview-rounds,.relocation,.compension-type,.hourly{
    width: 100% !important;
}
.job-date,.company,.relocation{
    margin-top: 24px;
}
.crudbtn{
    flex-wrap: wrap;
}
.job-type label, .work-type label, .relocation label, 
.compension-type label, .bonus-type label, .job-year label, 
.job-id label, .job-date label, .certi-type label, .job-title label,
 .interview-rounds label, .text-term label, .company label{
font-size: 18px;
}
.toggle-radio > input + label:before{
    height: 0px;
}
}

@media (max-width:584px) {
    .postcontainer2{
        padding: 10px;
    }

}
@media (max-width:420px)  {
    .crudbtn button{
        padding: 8px !important;
    }
    .mainpostjob{
        padding-top: 83px;
    }
}