.CRmainpreview{
    padding-top: 120px;
    padding-bottom: 40px;
    background: var(--bg-white-color);
    overflow-x: hidden;
    padding-left: 40px;
    padding-right: 40px;
}
.CRpostpreviewcontainer{
    background-color: var(--bg-white-color);
    /* background-image: url("/src/assets/texture2.jpg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 8px 24px #00000012;
    border-radius: 8px;
    max-width: 100%;
    /* margin: auto; */
    /*padding: 40px 80px;*/
}
.RP-head{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    padding: 13px 10px;
    margin-top: 15px;
    border-radius: 6px; 
}
.RP-head p , .RP-head label {
    color:var(--bg-white-color) !important;
}
.profile-imageRP{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    
}
.RDheadtitle{ 
    padding: 6px 8px;
    border-radius: 6px;
    background: var(--bg-white-color);
    border:1px solid var(--primary-color);
    width: 100%;
}
.fvthead {
    margin-bottom: 20px;
}
.RDheadtitle h2{
    font-size: 20px !important;
    line-height: 24px !important;
    color: var(--primary-color) !important;
    font-weight: 500;
    text-align: center;
    font-family: var(--font-family);
    margin-bottom: 0px !important;
    /*text-transform: uppercase;*/
}
.backbtnRD button{
    /* width: 109px;
    padding: 6px 25px;
    background: var(--primary-color);
    color: white;
    border: 1px solid var(--primary-color);
    border-radius: 4px; */
    border: none;
    background: none;
    color: var(--primary-color);
    margin-bottom: 10px;

}
.IconsRD img{
    width: 22px;
    height: 22px;
}
.CR-job-title{
    font-size: 20px !important;
    font-weight: 500 !important;
}
.active_for_job {
    border: 1px solid var(--secondary-color);
    background-color: var(--secondary-color);
    /* width: 85%; */
    margin-top: 17px;
    text-align: left;
    /* margin: auto; */
    padding: 2px 20px;
    border-radius: 6px;
  /* gap: 6px; */
}
.CRpdf{
    padding: 10px 10px;
    height: 936px;
}
.CRallsection{
    padding: 30px 40px;
    width: 100%;
}
.highlight {
    background-color: var(--highlight-color);
    font-weight: bold;
}
/* Add this to your CSS stylesheet */
.custom-half-width-modal {
    max-width: 60%; /* Adjust the width as needed */

}

.CRpostpreviewcontainer h2{
    font-family: var(--font-family);
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    /* padding-top: 10px; */
    line-height: 28px;
    color: var(--bg-white-color);
}
.CRpostpreviewcontainer h3 , .CRjob-desc h3{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    padding-top: 10px;

    padding-bottom: 15px;
}
.CRpostpreviewcontainer h3{
    color: #4C5966;
}
.CRjob-desc h3{
    color: var(--font-black-color);
}
label {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #828E99;
}

.CRjobpreview p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: var(--font-black-color);
    margin-bottom: 0px !important;
}
.CRjobpreview span{
    font-size: 17px;

}
.CRgeneralpreview{
    /* border:1px solid #bfbfbf; */
    padding-left: 13px;
    padding-right: 13px;
    border-radius: 8px;
    /* padding-top: 15px; */
    /* padding-bottom: 20px; */
}
.CRdatepreview,.CRjobdescpreview{
    margin-bottom: 10px;
}
.CRjobpreview-screen{
    padding-top: 13px;
}
.CRjobpreview-screen h4{
    font-family: var(--font-family);
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 17px;
color: var(--primary-color);
}
.CRjobpreview-title{
    padding-top: 15px;
}
.CRrespreview{
display: flex;
justify-content: space-between;
}
.CRrespreview p{
    font-family: var(--font-family);
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 17px;
color: var(--font-black-color);
margin-bottom: 0px;
}
.CRresumeicon{
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 10px;
}
.CRjobpreview {
    display: flex;
    align-items: baseline;
    padding-top: 7px;
}
.CRjobpreview label {
    white-space: nowrap; /* Label ko ek line mein rakhein */
    margin-right: 5px; /* Spacing dijiye label aur span ke beech */
}
.CRjob-p1 , .CRjob-p2 , .CRjob-p3, .CRjob-p4{
    display: flex;
    align-items: baseline;
}
.CRjob-p2 ,.CRjob-p3, .CRjob-p4{
    margin-left: 20px;
}
.CRjob-desc{
    padding: 20px 0px;
}
.CRjob-desc textarea {
    width: 100%;
    height: 250px;
    background: var(--bg-white-color);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    border: none;
    line-height: 28px;
    padding: 15px 15px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
}
.CRlabelcol{
    font-weight: 900;
    font-size: 17px;
}
.CRjobid p{
    padding-left: 15px;
}
.CRjob-btn{
    display: flex;
    justify-content: end;
    padding: 20px 0px;
    
}
.CRjob-btn button{
    padding: 10px 30px;
    background:  var(--bg-white-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-color);
}
.rectangel-image{
    width: 5%;
    height:5px;
    position: absolute;
    top: -2px;
    left: 0;
}
.rectangel-image2{
     width: 5%;
    height:5px;
    position: absolute;
    top: 14px;
    left: 0;
}
.hr-style{
    height: 1px;
    /*border: none;*/
    /*background-color: black;*/
    margin-left: 5%;
    width: 95%;
}
.custom-hr {
    margin-top: 0px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 8px solid var(--secondary-color);
    background: var(--secondary-color);
    opacity: 1;
}


.CRjob-post :nth-child(1){
    border: 1px solid  var(--third-color);
    color: var(--third-color);
    margin-right: 14px;
}
.CRjob-post :nth-child(2){
    border: 1px solid var(--primary-color);
    color:  var(--bg-white-color);
    background: var(--primary-color);
}
/* @media (min-width:1500px) and (max-width:3000px) {
    .CRpostpreviewcontainer{
        width: 50%;
    }
} */


@media (max-width:550px){
    .CRjobpreview{
        display: flex;
        flex-wrap: wrap;
        /*flex-direction: column;*/
    }
    .user-content{
        flex-direction: column !important;
        gap: 16px;
    }
}