/* VideoResumeGuide.css */

.sp-background-style {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    width: 100%;

}

.sp-about-us-content {
    display: flex;
    font-family: var(--font-family);
    padding-top: 100px;
    padding-left: 70px;
    padding-right: 70px;
}

.sp-text-content {
    width: 100%;
    float: left;
}



/* Style headings */
.sp-about-us-content h1 {
    font-size: 24px;
    font-weight: bold;
    /* Add any other heading styles here */
}

/* Style paragraphs */
.sp-about-us-content p {
    font-size: 16px;
    /* Add any other paragraph styles here */
}

/* Style lists and make the steps clickable */
.sp-about-us-content ol {
    margin-left: 20px;
    font-size: 16px;
    /* Add any other list styles here */
}

