
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "font-generic.css";

.page-wrapper{
    /*max-width: 1800px;*/
    margin: 0 auto;
}

.bg-white{
    /*max-width: 1800px;*/
}

.main-faq{
    width: 90%;
    display: flex;
    margin: 0 auto;
    padding: 2.5rem 0;
}
.main-faq> .faq-heading{
    width: 30%;
    padding-left: 5rem;
}
.main-faq> .faq-heading > h2{
    width: 50%;
    color: #000;
    text-align: left;
    font-size: 3rem;
    line-height: 3.625rem;
    letter-spacing: -0.015em;
}
.main-faq>.faq-content{
    width: 70%;
}
.accordion-item{
    border: none;
    border-bottom: 1px solid #dee2e6;
}


.info-section{

    width: 90%;
    margin: 2.5rem auto;
    padding: 2.5rem 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: #c3dae8;
    border: 1px;
    border-radius:25px;
}
.lp-card-body {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.lp-card-container {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
}

.lp-card-inner {
    height: 80px;
}

.banner_image{
    margin-top: 40px;
    margin-bottom: 40px;

}
.info-section-content{
    width: 75%;
    margin: 0 auto;
}
.landing-page {
    /* background-color: #f1f1f1; */
    background-image: url("/src/assets/landingimages.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    height: 84vh;
    width: 100%;
    padding: 0 5rem;
    flex-wrap: wrap;
}

.left_content{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.4rem;
    margin-bottom: 0; /* Add this line to reduce bottom margin */

}
.left_content h1{
    /* color: #2B3940; */
    color: #fff;
    font-size: 3.6rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -0.9px;
    width: 90%;
    margin-bottom: 1rem;
    padding-top:50px ;
}
.left_content .search_content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
.search_title_container{
    width: 40%;
    margin-right: 10px;
}

.search_title{
    background:  var(--bg-white-color);
    height: 45px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 40%;
    margin-right: 10px;

    border-radius: 5px 5px 5px 5px;

}
.search_title img{
    height: 20px;
}
.search_title input{
    border: none;
    width: 100%;
}
.search_title input:focus {
    outline: none;
    border: none;
}

.select_content{
    background:  var(--bg-white-color);
    height: 45px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 40%;
    border-radius: 5px;
    align-items: center;
}

.select_content img{
    height: 20px;
}
.select_content input{
    border: none;
    width: 100%;
}

.social-content {
    height: 2rem;
    width: 2rem;
    background-color: var(--landing-primary-color); /* Default background color */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.facebookIcon{
    color: #316FF6;
}
.twitterIcon{
    color: #00acee;
}
.linkedinIcon{
    color: #0072b1;
}
/*.social-content:hover{*/
/*    background-color: var(--primary-color);*/
/*}*/
/*.social-content svg:hover{*/
/*    color: #fff;*/
/*}*/
.social-content svg {
    color: #fff; /* Default icon color */
}

.social-content:hover {
    background-color: #4f5252; /* Background color on hover */
}

.social-content:hover svg {
    color: var(--landing-primary-color); /* Icon color on hover */
}
.select_content input:focus {
    outline: none;
    border: none;
}

.left_content .btn_search{
    color: #fff;
    text-align: center;
    font-size: 17px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    /*text-transform: uppercase;*/
    padding: 12px 36px;
    border-radius: 5px 5px 5px 5px;
    /*border: 1px solid var(--primary-color);*/
    border: 1px solid var(--landing-primary-color);
    /*background: var(--primary-color);*/
    background: var(--landing-primary-color);
}

.left_content p{
    /* color: #7E8989; */
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.26px;
    font-family: var(--font-family);
}
.left_content p span{
    /* color: #2B3940; */
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.26px;
    font-family: var(--font-family);
}

.btn_container{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    column-gap: 1.2rem;
    margin-top: 0; /* Add this line to reduce top margin */

}
.btn_container .btn_1{
    padding: 10px 28px;
    /*width: 140px;*/
    border-radius: 24px;
    /*background: var(--primary-color);*/
    background: var(--landing-primary-color);
    color: #fff;
    border: none;
    /*text-transform: uppercase;*/
    font-size: 17px;
    font-weight: 700;
    font-family: var(--font-family);
}
.btn_container .btn_2{
    padding: 10px 10px;
    width: 141px;
    border-radius: 24px;
    /*color: var(--primary-color);*/
    color: var(--landing-primary-color);
    /*border: 1px solid var(--primary-color);;*/
    border: 1px solid var(--landing-primary-color);
    /*text-transform: uppercase;*/
    font-size: 17px;
    font-weight: 700;
    font-family: var(--font-family);
}
.btn_container, .left_content {
    box-sizing: border-box;
}

.header_custom{
    width: 100%;
}

.right_content{
    width: 40%;
}
.right_content img{
    /*width: 100%;*/
    height: 50vh;
}
.height_full_page{
    height: auto;
    overflow: hidden;
    /*position: fixed;*/
}

.landing-content {
    text-align: center;
}

.landing-title {
    font-size: 3rem;
    color: #333;
    margin-bottom: 1rem;
}

.landing-subtitle {
    font-size: 1.5rem;
    color: #777;
    margin-bottom: 2rem;
}

.landing-buttons {
    display: flex;
    justify-content: center;
}
.nav_link{
    /*color: var(--primary-color);*/
    color: var(--landing-primary-color);
    text-align: center;
    font-size: 17px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
}
.active_link{
    color: var(--landing-primary-color) !important;
}

.landing-button {
    display: inline-block;
    padding: 1rem 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s ease;
}
.landing-button:hover,
.landing-button:focus {
    color: #fff;
}

.landing-button:active {
    color: #fff;
}
.jobseeker-button {
    background-color: var(--landing-primary-color);
}

.employer-button {
    background-color: var(--landing-primary-color);
}
.nav-landing-page{
    box-shadow: none !important;
    /* height: 16vh; */
    /*position: relative;*/
    position: fixed;
    width: 100%;
    z-index: 50;
    background-color: #f1f1f1;
}
/* ---------------------------About US ------------------------- */
.aboutussec-lp{
    display: flex;
    justify-content: space-between;
    background:  var(--bg-white-color);
    width:90%;
    margin:auto;
    /*padding: 50px 0px;*/
    gap: 6%;
}
.aboutussec-lp img{
    object-fit: contain;
    max-width: 47%;
}
.aboutrt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*padding-right: 100px;*/
    width: 47%;
    /*padding-left: 25px;*/
}
.aboutrt p{
    font-size: 18px;
    font-family: var(--font-family);
    font-style: normal;
    line-height: 1.5;
    text-align: justify;
}
.aboutrt h3{
    font-family: var(--font-family);
    font-size: 37px;
    font-weight: 700;
    padding-bottom: 15px;
}
/*---------------------Registration Section ----------------------- */
.regmain-lp{
background-color: #dfdfdf;
}
.reg-lp{
   width: 88%;
   margin: auto;
    display: flex;
    justify-content: center;
    column-gap: 30px;
    padding: 70px 0px;
}
.reg-lp button{
    padding: 10px 10px;
    border-radius: 24px;
    /*text-transform: uppercase;*/
    font-size: 17px;
    font-weight: 700;
    font-family: var(--font-family);
}
.reg-lp button:nth-child(1){
    /*background: var(--primary-color);*/
    background: var(--landing-primary-color);
    width: 140px;
    color: #fff;
    border: none; 
}
.reg-lp button:nth-child(2){
    /*color: var(--primary-color);*/
    color: var(--landing-primary-color);
    width: 141px;
    /*border: 1px solid var(--primary-color);*/
    border: 1px solid var(--landing-primary-color);
}
/*--------------------------------Footer -------------------------- */
.footermain-lp{
/*background-color: #222933;*/


    /*background-color: #333333;*/
    /*background-color: #001f3f;*/
    /*background: linear-gradient(to bottom, #001f3f, #000000);*/
    /*background: linear-gradient(to bottom, var(--primary-color), #001f3f);*/
    /*background: linear-gradient(to bottom, #007a86, #001f3f);*/
    /*background: linear-gradient(to bottom, #888888, #000000);*/
    background: linear-gradient(to bottom, var(--landing-primary-color), var(--landing-primary-color));
    /*background: linear-gradient(to bottom, #007586ff, #007586ff);*/

    padding-top: 50px ;

}
.footer-lp{
    width: 85%;
    margin: auto;
}

.footerlower-lp{
    display: flex;
    justify-content: space-between;
}
.footerlower-lp p,.footersocialicon-lp p{
    color:  var(--bg-white-color);
    margin-bottom: 0px;
    font-family: var(--font-family);
    font-size: 20px;
}
.footerlower-lp h3{
    color:  var(--bg-white-color);
    font-family: var(--font-family);
    font-size: 30px;
}
.footersocialicon-lp{
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.footercontact-lp img{
    width: 40px;
    height: 40px;
}
.footercontact-lp{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.footercol1-lp{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}
.footercol2-lp,.footercol3-lp,.footercol4-lp,.footercol5-lp{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.footercol2-lp p{
    cursor: pointer;
}
.footercol3-lp p{
    cursor: pointer;
}
.footercol5-lp p{
    cursor: pointer;
}

.search-container{
    width: 100%;
    max-width: 700px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    margin: 10px auto;
    padding: 2rem;
}
.search-container h2{
    font-style: normal;
    font-weight: 500;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: var(--font-black-color);
}
input::placeholder {
    font-family: var(--font-family);
}

h2{
    font-family: var(--font-family);

}
.below_main_footer{
    background-color: var(--font-black-color);
    margin-top: 50px;
    padding: 1rem 0;
}
.below_main_footer_content{
    color:  var(--bg-white-color);
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family);

}
.app_content{
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.app_content1 {
    width: 100%; /* Occupy full width */
    display: flex;
    justify-content: center; /* Center align child elements horizontally */
    align-items: center; /* Center align child elements vertically */
    gap: 1rem;
}
.app_content, .play_img{
    height: 45px;
    border-radius: 10px;

}

.app_content, .app_img{
    height: 45px;
    border-radius: 10px;
}
.mobile_img{
    width: 90%;
    margin: 0 auto;
    padding: 2.5rem 0;
}
.mobile_img_logo{
    width: 100%;
    padding-left:10px;
    padding-right:10px;

}
.accordion-button> strong{
    padding: 2rem 0;
}
.custom-faq-heading> .accordion-button> strong {
    padding-top: 0.5rem ;
}
.accordion-button{
    padding: 0;
}
.accordion-button:not(.collapsed){
    color: #212529;
    background-color: transparent;
    border-bottom: none;
    box-shadow: none;
}
.accordion-button:focus,
.accordion-button:active {
    outline: none;
    border-color: #fff;
    box-shadow: none;
}
.accordion-body{
    padding: 0;
}
.accordion-button::after{
    width: 0.8rem;
    height: 0.8rem;
    background-size: 0.8rem;
}

@media (max-width: 1250px) {
    .main-faq {
        flex-direction: column;
        justify-content: space-between;
        align-items: center; /* Header ko top left mein laane ke liye */
        gap: 2rem;

    }
    .main-faq>.faq-content {
        width: 90%;
    }
    .main-faq> .faq-heading {
        width: 100%;
        padding-left: 0;
    }
    .main-faq> .faq-heading > h2{
        width: 100%;
        color: #000;
        text-align: center;
        font-size: 2.5rem;
        line-height: 3.625rem;
        letter-spacing: -0.015em;
    }
    .left_content{
        width: 100%;
    }
    .left_content h1 {
        font-size: 3rem;
        line-height: 60px;
        width: 100%;
    }
    .left_content .search_content{
        width: 100%;
        flex-wrap: wrap;
        row-gap: 1rem;
    }
    .aboutussec-lp img{
        object-fit: contain;
        max-width: 100%;
    }
    .aboutussec-lp{

        flex-wrap: wrap;
        gap: 1rem;
    }
    .aboutrt{
        width: 100%;

        padding-right: 20px;
        justify-content: start;
    }
    .footer-lp{
        width: 98%;
    }
    .footerlower-lp{
        gap: 1rem;
    }
}

@media (max-width: 669px) {



    .landing-page{
        height: auto;
        padding: 2rem;
    }

    .select_content{
        width: 100%;
    }
    .search_title{
        width: 100%;
        margin: 0;
    }
    .aboutussec-lp{
        flex-wrap: wrap;
        gap: 1rem;
    }
    .aboutlft{
        margin: 0 auto;
    }
    .aboutussec-lp img{
        object-fit: contain;
        max-width: 100%;
    }
    .aboutrt{
        width: 100%;
    }
    .footer-lp{
        width: 70%;
    }
    .footerlower-lp{
        flex-direction: column;
    }
    .btn_container{
        margin-top: 2rem;
    }
    .info-section{
        flex-direction: column;
        gap: 1rem;
    }
    .info-section>.card{
        width: 100% !important;
    }
}