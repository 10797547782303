/* VideoResumeGuide.css */

.vrg-background-style {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
}

.vrg-video-resume-content {
    display: flex;
    font-family: var(--font-family);
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
}

.vrg-text-content {
    width: 60%;
    float: left;
}

.vrg-image-content {
    width: 40%;
    float: left;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px; /* Add padding to the container */
    background-color: #fff; /* Add a background color */
    border: 2px solid var(--font-black-color); /* Add a border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a box shadow */
    border-radius: 8px; /* Add border radius for rounded corners */


}

.vrg-image-content img {
    max-width: 100%;
    max-height: 100%;
}

/* Style headings */
.vrg-video-resume-content h1 {
    font-size: 24px;
    font-weight: bold;
    /* Add any other heading styles here */
}

/* Style paragraphs */
.vrg-video-resume-content p {
    font-size: 16px;
    /* Add any other paragraph styles here */
}

/* Style lists and make the steps clickable */
.vrg-video-resume-content ol {
    margin-left: 20px;
    font-size: 16px;
    /* Add any other list styles here */
}

/*.video-resume-content ol li {*/
/*    text-decoration: underline;*/
/*    cursor: pointer;*/
/*    color: #007bff; !* Change the color to a link color *!*/
/*}*/

.vrg-ol-clickable:hover {
    color: var(--secondary-color); /* Change the color when hovering */
}
.vrg-ol-clickable{
    /*text-decoration: underline;*/
    cursor: pointer;
    color: var(--primary-color); /* Change the color to a link color */
}
