.main {
  background-color:  var(--bg-white-color);
  padding-bottom: 50px;
}
.nav {
  position: fixed;
  width: 100%;
  z-index: 50;
  box-shadow: #cbcbcb52 0px 8px 24px;
}

/*--------------------------------------Nav Bar -------------------------- */
.postbtn {
  display: flex;
  align-items: center;
}

.postbtn button a {
  text-decoration: none;
  color:  var(--bg-white-color);
}

.navpad {
  padding: 20px;
}

.header-icon img {
  /* margin-right: 20px; */
  width: 22px;
}

.postbtn .resume-ser,
.postbtn .resume-ser2 {
  /* margin-right: 20px; */
  border-radius: 50px;
  padding: 8px 10px;
  /* margin-left: 12px; */
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 21px;
  width: 210px;
}
.postbtn .resume-ser2 {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}
/* .postbtn button{
    margin-right: 20px;
} */
.postbtn button a {
  color:  var(--bg-white-color);
}
.resume-ser a {
  color: white !important;
}
.postbtn .resume-ser {
  border-color: var(--secondary-color) !important;
  background-color: var(--secondary-color);
}

.dropdown-item:active{
  background-color: var(--primary-color);

}
.dropdown-item p{
  margin: 0;
}
.dropdown-item:active p,
.dropdown-item:active svg{
  color:  var(--bg-white-color);
}
.icon_color{
  color: #545454;
}
.icon_color:active{
  color:  var(--bg-white-color);
  cursor:pointer;
}

.mobileMenu hr{
  margin: 0;
}

.menu_content{
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  padding: 0.7rem;
  cursor: pointer;
}
.menu_content p{
  margin: 0;
}
.menu_content:hover{
  background-color: #e9ecef;
}
.menu_content:active{
  background-color: var(--primary-color);
  color: #fff;
}
.menu_content:active p{
  color: #fff;
}

.notification_content_unread{
  background-color: #378fe933;
}
.notification_content_read{
  background-color:  var(--bg-white-color);
}
.notification_hover:hover{
  background-color: #e9ecef;
  color: #1e2125;
  cursor: pointer;
}
.user_logo_img{
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
}

.currentuser_logo_img{
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
}

.div_notf_logo{
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color:  var(--bg-white-color);
}
.div_notf_logo h4{
  margin: 0;
  letter-spacing: 1px;
}


.div_user_logo{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color:  var(--bg-white-color);
}
.div_user_logo h4{
  margin: 0;
  letter-spacing: 1px;
}



.icon-container {
  position: relative;
  display: inline-block;
}

.number-badge {
  position: absolute;
  top: -13px;
  right: -11px;
  background-color: var(--secondary-color);
  color:  var(--bg-white-color);
  border-radius: 50%;
  font-size: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*@media (max-width: 768px) {*/
/*  .nav {*/
/*    display: none;*/
/*  }*/
/*}*/
