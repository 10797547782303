@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&family=Yantramanav:wght@100;300;400;500;700&display=swap");

* {
  padding: 0px;
  margin: 0px;
}
.modal-open{
  overflow-y: scroll !important;
  padding-right: 0 !important;

}

.row > * {
  padding: 0px !important;
}

a {
  text-decoration: none !important;
  background-color: none;
}
.maindashbord {
  background: #f5f6f7;
  padding-top: 85px;
}

.dashjobicon p {
  font-family: var(--font-family);
  font-style: normal;
  color: #4c5966;
  font-size: 16px;
  font-weight: 400;
}

.resume {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.resume p {
  font-family: var(--font-family);
  font-style: normal;
  color: #828e99;
  font-size: 16px;
  font-weight: 400;
}

.resume p a {
  color: #828e99;
}

.dashjobicon p a {
  color: #4c5966;
}
.post-list {
  margin-top: 10px;
}

.post-container {
  background:  var(--bg-white-color);
  padding: 18px 18px;
  box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
  border-radius: 8px;
  margin-top: 24px;
}

.analytics h2 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}

.analytics h2 a {
  color: black;
}
p {
  margin-left: 0px !important;
}

.mainpage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
}

.resumecol {
  max-width: 350px;
  width: 100%;
  padding-top: 20px;
  padding-right: 40px;
  padding-left: 20px;
  background:  var(--bg-white-color);
  border-radius: 8px;
}

.postcol {
  max-width: 530px;
  width: 100%;
}

.dashboardcol {
  max-width: 350px;
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 40px;
  background:  var(--bg-white-color);
  border-radius: 8px;
}

/*------------------------------------- First Column --------------- */
.favourite {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.fvtresum,
.activejob {
  display: flex;
  align-items: center;
}

.fvtresumtxt,
.activejobtxt {
  margin-left: 15px;
}

.fvtresumtxt h6,
.activejobtxt h6 {
  font-family: var(--font-family);
  color: #4c5966;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
}

.fvtresumtxt h6 a,
.activejobtxt h6 a {
  color: #4c5966;
}

.fvtresumtxt p,
.activejobtxt p,
.posttxt p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 20px;
  color: #828e99;
}

.postshare p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #828e99;
  padding-left: 11px;
}

.postshare p a {
  color: #828e99;
}

.fvtresumtxt p a,
.activejobtxt p a,
.posttxt p a {
  color: #828e99;
}

.creatpost p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
  color: #828e99;
}

.posttxt p:nth-child(1) {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #242833;
}

.activejob {
  padding-top: 20px;
  width: fit-content;
}

.creatpost {
  background:  var(--bg-white-color);
  padding: 18px 18px;
  box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
  border-radius: 8px;
}

.showpost {
  background:  var(--bg-white-color);
  padding: 18px 18px;
  box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
  border-radius: 8px;
  margin-top: 24px;
}
.btn-primary-color {
  background-color: #003580;
}
.btn-primary-color:hover {
  background-color: #003580;
}
.mainpost img {
  /* max-width: 476px; */
  width: 100%;
  margin: auto;
}
.bt-toast .eirsPS button.btn-dismiss {
  font-size: 2.0rem !important;
}
.mainpost ul {
  padding-top: 10px;
  padding-left: 20px;
}
.carousel-image{
  max-height: 400px;
  max-width: 800px;
  object-fit: contain;
  background: aliceblue;
  border-radius: 10px;
  /*object-fit: cover;*/
}
.social-media-full-image{
  height: 80vh !important;
  max-height: 80vh !important;
  align-content: center !important;
  max-width: 100% !important;
  object-fit: contain;
}
.social-media-custom-modal .modal-content {
  background-color: aliceblue;
}

.social-media-custom-modal-header {
  background-color: var(--primary-color);
  color: white; /* Optional: For better visibility of text on blue background */
  padding: 25px; /* Reset default padding */

}
.social-media-close-icon {
  cursor: pointer;
  position: absolute;
  /*top: 0.5rem; !* Adjust for spacing as needed *!*/
  right: 0.5rem; /* Adjust for spacing as needed */
}



/* Example CSS */
.carousel .thumbs-wrapper {
  margin-top: 10px; /* Adjust margin top as needed */
}

.carousel .thumb {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%; /* Add rounded corners */
  /*margin-right: 10px; !* Adjust margin right as needed *!*/
}
.carousel .thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50% !important;
}
.carousel .thumb.selected {
  border: 1px solid var(--secondary-color) !important; /* Border color for the selected thumbnail */
}
.custom-info-icon {
  margin-top: -5px !important;
}
.card-image{
  border-radius: 25px;
}

.mainpost p,
.mainpost ul li {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 0px;
  color: #4a4a4a;
}

.postprofile {
  display: flex;
  align-items: center;
}

.posttxt {
  padding-left: 13px;
}

.postdots {
  display: flex;
  justify-content: end;
  align-items: center;
}

.postcontent p {
  font-size: 14px;
}

.postshare {
  display: flex;
  align-items: baseline;
  margin-right: 30px;
}

.postshare img {
  width: 20px;
}

#postcreat {
  display: none;
}

.posticons {
  display: flex;
  padding-top: 25px !important;
}

/*---------------------------------First Column ------------------------*/

.dashjobicon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashjobicon p:nth-child(2) {
  color: var(--primary-color);
  background-color: #f5f5f5;
  border: 1px solid var(--primary-color);
  text-align: center;
  line-height: 23px;
  padding: 4px;
  font-weight: 700;
  width: 33px;
  height: 33px;
  border-radius: 50%;
}

.dashjobicon p:nth-child(2) a {
  color: var(--primary-color);
}

/*------------------------------------ Second Column ------------------------------ */
textarea {
  width: 100%;
  border: none;
}

::placeholder {
  color: #4c5966;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
  color: #828e99;
}

.postmaterial {
  display: flex;
}

.postmaterial img {
  padding-right: 12px;
  width: 40px;
}

.postpublish p {
  margin-right: 13px;
}

.postpublish button {
  padding: 4px 20px;
  background: var(--primary-color);
  color:  var(--bg-white-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

/* ----------------------------------------------------------------- */
/*                         Post A JOb                               */
/* ----------------------------------------------------------------- */

.postcontainer {
  position: relative;
  background-color:  var(--bg-white-color);
  max-width: 60%;
  margin: auto;
  padding: 20px 30px;
}

h5 {
  position: relative;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #17181a;
}
.custom-image{
  height: 140px;
  /*width: 140px;*/
  object-fit: cover;
}
.custom-title-size{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.custom-text-size{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.no_post_data{
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
}



 /*.f-width-div {*/
 /*  max-width: 100%; !* Limit the width of the parent div *!*/
 /*  overflow: hidden; !* Hide any content that overflows the div *!*/
 /*  white-space: nowrap; !* Prevent the content from wrapping *!*/
 /*}*/

.full-width-p {
  display: inline-block; /* Ensure the paragraph behaves like a block element */
  white-space: nowrap; /* Prevent the content from wrapping */
}

/*.info-span {*/
/*  display: inline-block; !* Ensure the info-span behaves like a block element *!*/
/*  white-space: nowrap; !* Prevent the content from wrapping *!*/
/*}*/


.fields {
  margin-top: 20px;
}

.formheading h5:after {
  /*content: "";*/
  display: block;
  max-width: 74%;
  width: 100%;
  height: 1px;
  background: #dbdbdb;
  left: 170px;
  top: 60%;
  position: absolute;
}

label {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4c5966;
}

input[type="text"] {
  width: 50%;

  padding: 8px 10px;
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
  border-radius: 8px;
  /*margin-left: 130px;*/
}
#posttype-cat {
  padding: 4px 10px;
  background: var(--primary-color);
  margin-right: 15px;
  color:  var(--bg-white-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

h1,h2,h3,h4,h5{
  font-family: var(--font-family)  !important;
  font-weight: bold  !important;
}

span, strong{
  font-family: var(--font-family)  !important;

}
p{
  font-family: var(--font-family)  !important;

}
label{
  font-weight: bold !important;
}
input[type=text] {
  font-family: var(--font-family)  !important;

}
.social-media-font {
  margin-bottom: 10px;
  font-family: var(--social-media-font-family) !important;

}
.social-media-font-p {
  font-family: var(--social-media-font-family) !important;
  font-weight: lighter;
  font-size: 15px;
}
button{
  font-family: var(--font-family)  !important;
}
.text-success{
  font-family: var(--font-family)  !important;
}
.text-danger{
  font-family: var(--font-family)  !important;
}

body {
  font-family: var(--font-family)  !important;
}
.generic-image-size{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.images-label-texts{
  width: 25px;
  height: 25px;
}
.normal-font {
  font-weight: normal !important;
  /* Add any other styles you need */
}

/* .fields{
    display: flex;
    justify-content: space-between;
} */

@media (min-width: 1200px) and (max-width: 1400px) {
  .resumecol {
    max-width: 280px;
    padding-right: 20px;
  }

  .postcol {
    max-width: 520px;
  }

  .dashboardcol {
    max-width: 280px;
    padding-left: 20px;
  }
}

@media (max-width:1143px){
  .postcontainer{
    max-width: 95%;

  }
}
@media (max-width:584px) {
  .postcontainer{
    padding: 10px;
  }

}