.wrapper{
    display: flex;
    justify-content: space-between;
    max-width: 2000px;
    width: 100%;
    margin: 0 auto;
}

.leftSide{
    width: 15%;
    background-color: var(--admin-background-color);
    height: 100vh;

}
.leftContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    gap: 1rem;
}
.leftContent img{
    width: 90%;
}

.content_divider{
    width: 100%;
    color: var(--divider-color);
    margin: 0;
}

.leftContent ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
}

.leftContent ul li{
    padding: 1rem;
    /*text-transform: uppercase;*/
    font-weight: 700;
    cursor: pointer;

}
.active{
    background-color: var(--primary-color);
    width: 100%;
    color: var(--divider-color);
}

.rightSide{
    width: 85%;
    background-color: var(--divider-color);
}

.right_top{
    width: 100%;
    z-index: 50;
    box-shadow: #cbcbcb52 0px 8px 24px;
    position: sticky;
    top: 0;
}
.right_bottom{
    padding: 2rem 0.5rem;
}


.modal_input_content{
    width: 50%;
}